import { Link, navigate } from "gatsby"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Button, Menu, Dropdown, Icon, Modal, Result } from 'antd'

class Header extends Component {
  state = {
    supportVisible: false
  }

  goBack(e) {
    e.preventDefault()
    window.history.go(-1)
    return false
  }

  componentDidMount() {
    window.addEventListener('helpMe', this.openModal)
  }

  componentWillUnmount() {
    window.removeEventListener('helpMe', this.openModal)
  }

  renderGoBack() {
    if (this.props.goBack) {
      return (
        <Link to="/" onClick={this.goBack} title="Go back" style={{
          float: `left`,
          padding: `1.45rem 1rem 1.5rem 1rem`
        }}>
          <svg width="50" height="40" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 125"><g fill="#cbb9ec"><path d="M 31.9375 20.96875 A 2.0001996 2.0001996 0 0 0 30.5625 21.59375 L 4.5625 48.59375 A 2.0001996 2.0001996 0 0 0 4.5625 51.375 L 30.5625 78.375 A 2.0001996 2.0001996 0 1 0 33.4375 75.59375 L 10.71875 52 L 94 52 A 2.0001996 2.0001996 0 1 0 94 48 L 10.71875 48 L 33.4375 24.375 A 2.0001996 2.0001996 0 0 0 31.9375 20.96875 z "/></g></svg>
        </Link>
      )
    }
  }

  openModal = () => {
    this.setState({supportVisible: true})
  }

  menuClick = ({key}) => {
    if (key === 'settings') navigate('/settings')
    else if (key === 'help') this.openModal()
    else if (this.props.menuCallback) this.props.menuCallback(key)
  }

  renderDivider() {
    if (this.props.menuOptions.length > 0)
      return <Menu.Divider />
  }

  renderMenu() {
    const menu = (
      <Menu onClick={this.menuClick} style={{
        marginTop: `-1.4rem`,
        marginRight: `1.6rem`
      }}>
        { this.props.menuOptions }
        { this.renderDivider() }
        <Menu.Item key="settings">
          <Icon type="setting" /> Settings
        </Menu.Item>
        <Menu.Item key="help">
          <Icon type="question-circle" /> Help
        </Menu.Item>
      </Menu>
    )

    return <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
      <Icon type="ellipsis" style={{
        fontSize: `2em`,
        color: `#cbb9ec`,
        float: `right`,
        padding: `1.5rem 2rem 1.5rem 1.5rem`,
        cursor: `pointer`
      }} className="hoverable" />
    </Dropdown>
  }

  renderModal() {
    return <Modal
      title="Contact Support"
      visible={this.state.supportVisible}
      footer={null}
      closable={true}
      centered={true}
      onCancel={() => this.setState({supportVisible: false})}
    >
      <Result icon={this.chatImg()} title="Need Help? Just Ask!" subTitle="You can reach us by SMS or email." extra={[
        <Button icon="message" type="primary" href="sms:+13126246245">Text (312) 624-6245</Button>,
        <Button icon="mail" href="mailto:support@hiome.com">Email support@hiome.com</Button>
      ]}/>
    </Modal>
  }

  chatImg() {
    return <svg width="216px" height="156px" viewBox="0 0 1080 780" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <path d="M521.62165,18.5 C416.13525,18.5 330.62165,75.31583 330.62165,145.4017 C330.62165,176.69559 347.68052,205.338 375.94365,227.46418 L375.94365,319.5678 L436.48975,259.02166 C463.979838,267.949333 492.71848,272.432952 521.62162,272.30339 C627.10801,272.30339 712.62162,215.48756 712.62162,145.4017 C712.62162,75.31584 627.10804,18.5 521.62165,18.5 Z" id="Shape" fill="#F2F2F2" fillRule="nonzero"></path>
                <g opacity="0.3" transform="translate(831.000000, 577.000000)">
                    <path d="M11.55655,73.15057 C19.3583025,77.3049774 28.9331198,76.0783812 35.4357069,70.0914952 C41.938294,64.1046092 43.9500738,54.6635072 40.4529939,46.5458182 C36.9559139,38.4281292 28.7128825,33.4047741 19.89525,34.0178 L18.90963,44.69727 L15.17823,34.89709 C9.92565781,36.5140476 5.52992379,40.1502306 2.957,45.00658 C2.04677078,46.7064297 1.38203483,48.5266982 0.98262,50.41309 C-0.968068785,59.5033857 3.34815037,68.784698 11.55655,73.15057 L11.55655,73.15057 Z" id="Shape" fill="#57B894" fillRule="nonzero"></path>
                    <path d="M18.97258,199.6824 C18.47807,190.41131 27.2859,183.50106 35.60367,179.3764 C43.92144,175.25174 53.50348,171.39868 57.33914,162.94377 C62.85166,150.79259 53.42052,137.61471 46.21814,126.38242 C40.8749189,118.047026 36.5384843,109.107701 33.29958,99.75151 C32.00149,96.00004 30.87099,92.11043 30.95142,88.14151 C31.06726,82.42586 33.66416,77.08951 36.24177,71.9868 C44.8285433,54.98806 53.81327,38.1982667 63.19595,21.61742" id="Shape" stroke="#3F3D56"></path>
                    <path d="M12.79176,70.53999 C20.5935135,74.6943882 30.1683256,73.4677855 36.6709071,67.4808984 C43.1734886,61.4940113 45.1852647,52.052913 41.6881855,43.9352274 C38.1911063,35.8175417 29.9480793,30.7941876 21.13045,31.40721 L20.14484,42.08669 L16.41344,32.28651 C11.1608652,33.9034626 6.76512945,37.5396471 4.19221,42.396 C3.28198054,44.0958471 2.61724136,45.9161118 2.21782,47.8025 C0.267131578,56.8928009 4.58335491,66.1741175 12.79176,70.53999 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M42.55209,14.95897 C45.8784554,8.69743776 52.1667803,4.56306511 59.23392,3.9912 L59.50328,14.31463 L64.14803,4.16983 C73.0909395,5.57131639 80.102112,12.5931193 81.4900476,21.5381419 C82.8779833,30.4831645 78.3242877,39.2994059 70.226558,43.3449673 C62.1288283,47.3905288 52.3446949,45.7373527 46.0256682,39.2558641 C39.7066414,32.7743754 38.3023149,22.9514368 42.55209,14.95899 L42.55209,14.95897 Z" id="Shape" fill="#57B894" fillRule="nonzero"></path>
                    <path d="M44.19904,11.4782 C47.5253865,5.2166595 53.8137026,1.08227391 60.88084,0.5104 L61.1502,10.83384 L65.79495,0.68903 C74.7378642,2.0905026 81.7490489,9.11229787 83.1369962,18.0573212 C84.5249435,27.0023445 79.9712561,35.8185938 71.8735278,39.8641638 C63.7757996,43.9097339 53.9916618,42.2565647 47.6726294,35.775078 C41.353597,29.2935914 39.9492658,19.4706501 44.19904,11.4782 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M42.57184,126.07475 C51.9136744,131.040838 63.4947846,128.207241 69.4884116,119.488966 C75.4820387,110.77069 73.9803033,98.9429191 65.99811,91.99911 L62.429,99.79511 L62.1592,89.35038 C62.143394,89.3399937 62.1268001,89.3308592 62.10957,89.32306 C55.5433662,85.8214369 47.6071619,86.0922911 41.2949719,90.0334399 C34.982782,93.9745888 31.2553096,100.986197 31.5187822,108.423063 C31.7822548,115.859929 35.9965722,122.590177 42.57184,126.07475 L42.57184,126.07475 Z" id="Shape" fill="#57B894" fillRule="nonzero"></path>
                    <path d="M44.07434,122.52563 C53.4161786,127.491739 64.9973091,124.658147 70.9909428,115.939859 C76.9845765,107.221571 75.4828268,95.3937832 67.50061,88.44998 L63.93149,96.24598 L63.6617,85.8012 C63.6458881,85.7908177 63.6292912,85.7816834 63.61206,85.77388 C57.0465883,82.2797105 49.1154821,82.5548284 42.8079151,86.4955439 C36.5003481,90.4362593 32.7752033,97.4435006 33.0364593,104.876292 C33.2977153,112.309083 37.5056558,119.037483 44.07434,122.52561 L44.07434,122.52563 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M16.27456,157.25941 C16.6024548,167.089579 23.770633,175.346588 33.4572656,177.052132 C43.1438981,178.757676 52.7012188,173.445558 56.3669399,164.318553 C60.032661,155.191548 56.8047859,144.744453 48.629461,139.276016 C40.4541361,133.80758 29.5660233,134.812558 22.52977,141.68503 L29.75477,156.80346 L18.24814,147.69309 C16.833472,150.677732 16.1566161,153.958582 16.27456,157.25941 Z" id="Shape" fill="#57B894" fillRule="nonzero"></path>
                    <path d="M17.50976,154.64883 C17.8376548,164.478999 25.005833,172.736008 34.6924656,174.441552 C44.3790981,176.147096 53.9364188,170.834978 57.6021399,161.707973 C61.267861,152.580968 58.0399859,142.133873 49.864661,136.665436 C41.6893361,131.197 30.8012233,132.201978 23.76497,139.07445 L30.98997,154.19287 L19.48334,145.0825 C18.0686764,148.067147 17.3918207,151.348 17.50976,154.64883 Z" id="Shape" stroke="#3F3D56"></path>
                </g>
                <g opacity="0.3" transform="translate(220.000000, 580.000000)">
                    <path d="M72.33084,73.15057 C64.5290875,77.3049774 54.9542702,76.0783812 48.4516831,70.0914952 C41.949096,64.1046092 39.9373162,54.6635072 43.4343961,46.5458182 C46.9314761,38.4281292 55.1745075,33.4047741 63.99214,34.0178 L64.97776,44.69727 L68.70916,34.89709 C73.9617322,36.5140476 78.3574662,40.1502306 80.93039,45.00658 C81.840618,46.7064313 82.5053571,48.5266991 82.90478,50.41309 C84.8554635,59.5033877 80.5392409,68.7846987 72.33084,73.15057 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M64.91478,199.6824 C65.40929,190.41131 56.60146,183.50106 48.28369,179.3764 C39.96592,175.25174 30.38388,171.39868 26.54822,162.94377 C21.0357,150.79259 30.46684,137.61471 37.66922,126.38242 C43.0124598,118.047038 47.3489145,109.107723 50.58784,99.75154 C51.88593,96.00007 53.01643,92.11046 52.936,88.14154 C52.82016,82.42589 50.22326,77.08954 47.64565,71.98683 C39.0588767,54.98809 30.07415,38.1982967 20.69147,21.61745" id="Shape" stroke="#3F3D56"></path>
                    <path d="M71.0956,70.53999 C63.2938465,74.6943882 53.7190344,73.4677855 47.2164529,67.4808984 C40.7138714,61.4940113 38.7020953,52.052913 42.1991745,43.9352274 C45.6962537,35.8175417 53.9392807,30.7941876 62.75691,31.40721 L63.74252,42.08669 L67.47392,32.28651 C72.7264986,33.9034603 77.1222382,37.539645 79.69516,42.396 C80.6053821,44.0958494 81.2701176,45.9161135 81.66954,47.8025 C83.6202284,56.8928009 79.3040051,66.1741175 71.0956,70.53999 L71.0956,70.53999 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M41.33527,14.95897 C38.0089046,8.69743776 31.7205797,4.56306511 24.65344,3.9912 L24.38409,14.31463 L19.73933,4.16983 C10.7964205,5.57131639 3.78524803,12.5931193 2.39731237,21.5381419 C1.00937671,30.4831645 5.56307227,39.2994059 13.660802,43.3449673 C21.7585317,47.3905288 31.5426651,45.7373527 37.8616918,39.2558641 C44.1807186,32.7743754 45.5850451,22.9514368 41.33527,14.95899 L41.33527,14.95897 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M39.68833,11.4782 C36.3619736,5.21665489 30.0736465,1.0822695 23.0065,0.5104 L22.73714,10.83384 L18.09239,0.68903 C9.14948068,2.09051418 2.13830674,9.11231478 0.750368434,18.0573364 C-0.637569873,27.0023581 3.91612242,35.8186005 12.0138502,39.8641647 C20.111578,43.9097289 29.8957114,42.2565569 36.2147406,35.7750713 C42.5337698,29.2935858 43.9381009,19.4706485 39.68833,11.4782 L39.68833,11.4782 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M41.31551,126.07475 C31.9736756,131.040838 20.3925654,128.207241 14.3989384,119.488966 C8.40531133,110.77069 9.9070467,98.9429191 17.88924,91.99911 L21.45835,99.79511 L21.72815,89.35038 C21.743956,89.3399937 21.7605499,89.3308592 21.77778,89.32306 C28.3434096,85.8272825 36.2756152,86.1014801 42.5841796,90.042288 C48.892744,93.9830959 52.618392,100.991278 52.3566596,108.424948 C52.0949272,115.858618 47.8856132,122.587418 41.31551,126.07478 L41.31551,126.07475 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M39.81302,122.52563 C30.4711814,127.491739 18.8900509,124.658147 12.8964172,115.939859 C6.9027835,107.221571 8.40453316,95.3937832 16.38675,88.44998 L19.95587,96.24598 L20.22566,85.80124 C20.2414719,85.7908577 20.2580688,85.7817234 20.2753,85.77392 C26.8407717,82.2797505 34.7718779,82.5548684 41.0794449,86.4955839 C47.3870119,90.4362993 51.1121567,97.4435406 50.8509007,104.876332 C50.5896447,112.309123 46.3817042,119.037523 39.81302,122.52565 L39.81302,122.52563 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M67.61284,157.25941 C67.2849452,167.089579 60.116767,175.346588 50.4301344,177.052132 C40.7435019,178.757676 31.1861812,173.445558 27.5204601,164.318553 C23.854739,155.191548 27.0826141,144.744453 35.257939,139.276016 C43.4332639,133.80758 54.3213767,134.812558 61.35763,141.68503 L54.13263,156.80346 L65.63926,147.69309 C67.053928,150.677732 67.7307839,153.958582 67.61284,157.25941 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M66.3776,154.64883 C66.0497052,164.478999 58.881527,172.736008 49.1948944,174.441552 C39.5082619,176.147096 29.9509412,170.834978 26.2852201,161.707973 C22.619499,152.580968 25.8473741,142.133873 34.022699,136.665436 C42.1980239,131.197 53.0861367,132.201978 60.12239,139.07445 L52.89739,154.19287 L64.40402,145.0825 C65.8186836,148.067147 66.4955393,151.348 66.3776,154.64883 L66.3776,154.64883 Z" id="Shape" stroke="#3F3D56"></path>
                </g>
                <g id="Group" opacity="0.3" transform="translate(740.000000, 579.000000)">
                    <path d="M72.33084,73.15057 C64.5290875,77.3049774 54.9542702,76.0783812 48.4516831,70.0914952 C41.949096,64.1046092 39.9373162,54.6635072 43.4343961,46.5458182 C46.9314761,38.4281292 55.1745075,33.4047741 63.99214,34.0178 L64.97776,44.69727 L68.70916,34.89709 C73.9617322,36.5140476 78.3574662,40.1502306 80.93039,45.00658 C81.840618,46.7064313 82.5053571,48.5266991 82.90478,50.41309 C84.8554635,59.5033877 80.5392409,68.7846987 72.33084,73.15057 L72.33084,73.15057 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M64.91478,199.6824 C65.40929,190.41131 56.60146,183.50106 48.28369,179.3764 C39.96592,175.25174 30.38388,171.39868 26.54822,162.94377 C21.0357,150.79259 30.46684,137.61471 37.66922,126.38242 C43.0124598,118.047038 47.3489145,109.107723 50.58784,99.75154 C51.88593,96.00007 53.01643,92.11046 52.936,88.14154 C52.82016,82.42589 50.22326,77.08954 47.64565,71.98683 C39.0588833,54.98809 30.0741567,38.1982967 20.69147,21.61745" id="Shape" stroke="#3F3D56"></path>
                    <path d="M71.0956,70.53999 C63.2938465,74.6943882 53.7190344,73.4677855 47.2164529,67.4808984 C40.7138714,61.4940113 38.7020953,52.052913 42.1991745,43.9352274 C45.6962537,35.8175417 53.9392807,30.7941876 62.75691,31.40721 L63.74252,42.08669 L67.47392,32.28651 C72.7264986,33.9034603 77.1222382,37.539645 79.69516,42.396 C80.6053821,44.0958494 81.2701176,45.9161135 81.66954,47.8025 C83.6202284,56.8928009 79.3040051,66.1741175 71.0956,70.53999 L71.0956,70.53999 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M41.33527,14.95897 C38.0089046,8.69743776 31.7205797,4.56306511 24.65344,3.9912 L24.38409,14.31463 L19.73933,4.16983 C10.7964205,5.57131639 3.78524803,12.5931193 2.39731237,21.5381419 C1.00937671,30.4831645 5.56307227,39.2994059 13.660802,43.3449673 C21.7585317,47.3905288 31.5426651,45.7373527 37.8616918,39.2558641 C44.1807186,32.7743754 45.5850451,22.9514368 41.33527,14.95899 L41.33527,14.95897 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M39.68833,11.4782 C36.3619736,5.21665489 30.0736465,1.0822695 23.0065,0.5104 L22.73714,10.83384 L18.09239,0.68903 C9.14948068,2.09051418 2.13830674,9.11231478 0.750368434,18.0573364 C-0.637569873,27.0023581 3.91612242,35.8186005 12.0138502,39.8641647 C20.111578,43.9097289 29.8957114,42.2565569 36.2147406,35.7750713 C42.5337698,29.2935858 43.9381009,19.4706485 39.68833,11.4782 L39.68833,11.4782 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M41.31551,126.07475 C31.9736756,131.040838 20.3925654,128.207241 14.3989384,119.488966 C8.40531133,110.77069 9.9070467,98.9429191 17.88924,91.99911 L21.45835,99.79511 L21.72815,89.35038 C21.743956,89.3399937 21.7605499,89.3308592 21.77778,89.32306 C28.3434096,85.8272825 36.2756152,86.1014801 42.5841796,90.042288 C48.892744,93.9830959 52.618392,100.991278 52.3566596,108.424948 C52.0949272,115.858618 47.8856132,122.587418 41.31551,126.07478 L41.31551,126.07475 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M39.81302,122.52563 C30.4711814,127.491739 18.8900509,124.658147 12.8964172,115.939859 C6.9027835,107.221571 8.40453316,95.3937832 16.38675,88.44998 L19.95587,96.24598 L20.22566,85.80124 C20.2414719,85.7908577 20.2580688,85.7817234 20.2753,85.77392 C26.8407717,82.2797505 34.7718779,82.5548684 41.0794449,86.4955839 C47.3870119,90.4362993 51.1121567,97.4435406 50.8509007,104.876332 C50.5896447,112.309123 46.3817042,119.037523 39.81302,122.52565 L39.81302,122.52563 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M67.61284,157.25941 C67.2849452,167.089579 60.116767,175.346588 50.4301344,177.052132 C40.7435019,178.757676 31.1861812,173.445558 27.5204601,164.318553 C23.854739,155.191548 27.0826141,144.744453 35.257939,139.276016 C43.4332639,133.80758 54.3213767,134.812558 61.35763,141.68503 L54.13263,156.80346 L65.63926,147.69309 C67.053928,150.677732 67.7307839,153.958582 67.61284,157.25941 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M66.3776,154.64883 C66.0497052,164.478999 58.881527,172.736008 49.1948944,174.441552 C39.5082619,176.147096 29.9509412,170.834978 26.2852201,161.707973 C22.619499,152.580968 25.8473741,142.133873 34.022699,136.665436 C42.1980239,131.197 53.0861367,132.201978 60.12239,139.07445 L52.89739,154.19287 L64.40402,145.0825 C65.8186836,148.067147 66.4955393,151.348 66.3776,154.64883 L66.3776,154.64883 Z" id="Shape" stroke="#3F3D56"></path>
                </g>
                <g id="Group" opacity="0.3" transform="translate(0.000000, 578.000000)">
                    <path d="M72.33084,73.15057 C64.5290875,77.3049774 54.9542702,76.0783812 48.4516831,70.0914952 C41.949096,64.1046092 39.9373162,54.6635072 43.4343961,46.5458182 C46.9314761,38.4281292 55.1745075,33.4047741 63.99214,34.0178 L64.97776,44.69727 L68.70916,34.89709 C73.9617322,36.5140476 78.3574662,40.1502306 80.93039,45.00658 C81.840618,46.7064313 82.5053571,48.5266991 82.90478,50.41309 C84.8554635,59.5033877 80.5392409,68.7846987 72.33084,73.15057 L72.33084,73.15057 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M64.91478,199.6824 C65.40929,190.41131 56.60146,183.50106 48.28369,179.3764 C39.96592,175.25174 30.38384,171.39871 26.54822,162.9438 C21.0357,150.79262 30.46684,137.61474 37.66922,126.38245 C43.0124619,118.047058 47.3489167,109.107734 50.58784,99.75154 C51.88593,96.00007 53.01643,92.11046 52.936,88.14154 C52.82016,82.42589 50.22326,77.08954 47.64565,71.98683 C39.0588633,54.98811 30.0741267,38.1983267 20.69144,21.61748" id="Shape" stroke="#3F3D56"></path>
                    <path d="M71.0956,70.53999 C63.2938465,74.6943882 53.7190344,73.4677855 47.2164529,67.4808984 C40.7138714,61.4940113 38.7020953,52.052913 42.1991745,43.9352274 C45.6962537,35.8175417 53.9392807,30.7941876 62.75691,31.40721 L63.74252,42.08669 L67.47392,32.28651 C72.7264986,33.9034603 77.1222382,37.539645 79.69516,42.396 C80.6053821,44.0958494 81.2701176,45.9161135 81.66954,47.8025 C83.6202284,56.8928009 79.3040051,66.1741175 71.0956,70.53999 L71.0956,70.53999 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M41.33527,14.95897 C38.0089046,8.69743776 31.7205797,4.56306511 24.65344,3.9912 L24.38409,14.31463 L19.73933,4.16983 C10.7964205,5.57131639 3.78524803,12.5931193 2.39731237,21.5381419 C1.00937671,30.4831645 5.56307227,39.2994059 13.660802,43.3449673 C21.7585317,47.3905288 31.5426651,45.7373527 37.8616918,39.2558641 C44.1807186,32.7743754 45.5850451,22.9514368 41.33527,14.95899 L41.33527,14.95897 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M39.68833,11.4782 C36.3619736,5.21665489 30.0736465,1.0822695 23.0065,0.5104 L22.73714,10.83384 L18.09239,0.68903 C9.14948068,2.09051418 2.13830674,9.11231478 0.750368434,18.0573364 C-0.637569873,27.0023581 3.91612242,35.8186005 12.0138502,39.8641647 C20.111578,43.9097289 29.8957114,42.2565569 36.2147406,35.7750713 C42.5337698,29.2935858 43.9381009,19.4706485 39.68833,11.4782 L39.68833,11.4782 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M41.31551,126.07475 C31.9736756,131.040838 20.3925654,128.207241 14.3989384,119.488966 C8.40531133,110.77069 9.9070467,98.9429191 17.88924,91.99911 L21.45835,99.79511 L21.72815,89.35038 C21.743956,89.3399937 21.7605499,89.3308592 21.77778,89.32306 C28.3434096,85.8272825 36.2756152,86.1014801 42.5841796,90.042288 C48.892744,93.9830959 52.618392,100.991278 52.3566596,108.424948 C52.0949272,115.858618 47.8856132,122.587418 41.31551,126.07478 L41.31551,126.07475 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M39.81302,122.52563 C30.4711814,127.491739 18.8900509,124.658147 12.8964172,115.939859 C6.9027835,107.221571 8.40453316,95.3937832 16.38675,88.44998 L19.95587,96.24598 L20.22566,85.8012 C20.2414719,85.7908177 20.2580688,85.7816834 20.2753,85.77388 C26.8407717,82.2797105 34.7718779,82.5548284 41.0794449,86.4955439 C47.3870119,90.4362593 51.1121567,97.4435006 50.8509007,104.876292 C50.5896447,112.309083 46.3817042,119.037483 39.81302,122.52561 L39.81302,122.52563 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M67.61284,157.25941 C67.2849452,167.089579 60.116767,175.346588 50.4301344,177.052132 C40.7435019,178.757676 31.1861812,173.445558 27.5204601,164.318553 C23.854739,155.191548 27.0826141,144.744453 35.257939,139.276016 C43.4332639,133.80758 54.3213767,134.812558 61.35763,141.68503 L54.13263,156.80346 L65.63926,147.69309 C67.053928,150.677732 67.7307839,153.958582 67.61284,157.25941 L67.61284,157.25941 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M66.3776,154.64883 C66.0497052,164.478999 58.881527,172.736008 49.1948944,174.441552 C39.5082619,176.147096 29.9509412,170.834978 26.2852201,161.707973 C22.619499,152.580968 25.8473741,142.133873 34.022699,136.665436 C42.1980239,131.197 53.0861367,132.201978 60.12239,139.07445 L52.89739,154.19287 L64.40402,145.0825 C65.8186836,148.067147 66.4955393,151.348 66.3776,154.64883 L66.3776,154.64883 Z" id="Shape" stroke="#3F3D56"></path>
                </g>
                <g id="Group" opacity="0.3" transform="translate(47.000000, 434.000000)">
                    <path d="M19.73999,124.81057 C33.141348,131.946745 49.5883622,129.839772 60.7580925,119.555876 C71.9278227,109.27198 75.3835279,93.0546529 69.3764634,79.1106018 C63.3693988,65.1665507 49.2100404,56.5377513 34.06367,57.59077 L32.37067,75.9353 L25.96108,59.1012 C16.9385407,61.8786966 9.38782322,68.1246991 4.96822,76.46663 C3.40468795,79.3865396 2.26284522,82.5132939 1.57675,85.75363 C-1.77398026,101.368346 5.64014703,117.311158 19.73999,124.81057 L19.73999,124.81057 Z" id="Shape" fill="#57B894" fillRule="nonzero"></path>
                    <path d="M32.47878,342.1589 C31.62933,326.23361 46.75887,314.36362 61.0466,307.2786 C75.33433,300.19358 91.79376,293.57498 98.38241,279.05168 C107.85147,258.17914 91.65125,235.54303 79.27941,216.2489 C70.1011615,201.930893 62.65231,186.57549 57.08873,170.50402 C54.85895,164.06002 52.91705,157.37866 53.05521,150.56115 C53.25421,140.74315 57.71498,131.57679 62.14263,122.81163 C76.8924367,93.6122967 92.3258333,64.7718533 108.44282,36.2903" id="Shape" stroke="#3F3D56"></path>
                    <path d="M21.86174,120.32627 C35.263097,127.462454 51.7101164,125.355488 62.8798523,115.071593 C74.0495881,104.787698 77.505297,88.5703672 71.4982317,74.6263127 C65.4911664,60.6822582 51.3318036,52.0534577 36.18543,53.10648 L34.49243,71.451 L28.08284,54.61688 C19.0603,57.394381 11.5095829,63.6403868 7.08998,71.98232 C5.52644376,74.9022277 4.38460082,78.0289826 3.69851,81.26932 C0.347766052,96.8840377 7.76189155,112.826859 21.86174,120.32627 L21.86174,120.32627 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M72.98216,24.85274 C78.6959613,14.0970494 89.4976592,6.99526573 101.63716,6.01296 L102.09984,23.7459 L110.07831,6.3198 C125.439814,8.72720237 137.483102,20.7887808 139.86719,36.1539205 C142.251277,51.5190602 134.429252,66.6629825 120.519536,73.6121691 C106.60982,80.5613558 89.8033164,77.7216562 78.948919,66.5882179 C68.0945216,55.4547796 65.6822403,38.5816246 72.98218,24.85274 L72.98216,24.85274 Z" id="Shape" fill="#57B894" fillRule="nonzero"></path>
                    <path d="M75.81117,18.87368 C81.5249741,8.11799202 92.3266704,1.01620938 104.46617,0.0339 L104.92885,17.76684 L112.90732,0.34074 C128.268824,2.74814237 140.312112,14.8097208 142.6962,30.1748605 C145.080287,45.5400002 137.258262,60.6839225 123.348546,67.6331091 C109.43883,74.5822958 92.6323264,71.7425962 81.777929,60.6091579 C70.9235316,49.4757196 68.5112503,32.6025646 75.81119,18.87368 L75.81117,18.87368 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M73.0161,215.72036 C89.0629155,224.250767 108.956198,219.383381 119.251662,204.407683 C129.547125,189.431984 126.967543,169.114999 113.25625,157.18736 L107.12546,170.57879 L106.66202,152.63749 C106.634866,152.619649 106.606359,152.603958 106.57676,152.59056 C95.2997127,146.595525 81.6809781,147.072117 70.8506019,153.84081 C60.0202258,160.609502 53.6236,172.641968 54.0702716,185.405695 C54.5169432,198.169422 61.7390522,209.725296 73.0161,215.72033 L73.0161,215.72036 Z" id="Shape" fill="#57B894" fillRule="nonzero"></path>
                    <path d="M75.59698,209.6239 C91.6437955,218.154307 111.537078,213.286921 121.832542,198.311223 C132.128005,183.335524 129.548423,163.018539 115.83713,151.0909 L109.70633,164.48232 L109.24289,146.54103 C109.215738,146.523185 109.187231,146.507494 109.15763,146.4941 C97.8805827,140.499067 84.2618493,140.975661 73.4314749,147.744354 C62.6011005,154.513047 56.2044767,166.545514 56.6511498,179.30924 C57.0978228,192.072966 64.3199325,203.628837 75.59698,209.62387 L75.59698,209.6239 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M27.84428,269.28739 C28.4075175,286.17303 40.7205598,300.356396 57.3596428,303.286071 C73.9987257,306.215745 90.4156843,297.090925 96.7124263,281.413134 C103.009168,265.735344 97.4645278,247.789986 83.4214726,238.396655 C69.3784174,229.003324 50.6755083,230.729613 38.58908,242.53471 L50.99974,268.5042 L31.23439,252.85499 C28.8043589,257.981812 27.6416921,263.617442 27.84428,269.28739 L27.84428,269.28739 Z" id="Shape" fill="#57B894" fillRule="nonzero"></path>
                    <path d="M29.96604,264.8031 C30.529284,281.688733 42.8423259,295.87209 59.4814033,298.801759 C76.1204806,301.731428 92.5374307,292.606608 98.8341683,276.928822 C105.130906,261.251037 99.5862665,243.305687 85.5432167,233.91236 C71.5001669,224.519032 52.7972653,226.245319 40.71084,238.05041 L53.1215,264.01988 L33.35614,248.37067 C30.926108,253.497502 29.7634445,259.133143 29.96604,264.8031 L29.96604,264.8031 Z" id="Shape" stroke="#3F3D56"></path>
                </g>
                <g id="Group" opacity="0.3" transform="translate(897.000000, 333.000000)">
                    <path d="M25.66361,445.27243 C24.56397,424.65674 44.14953,409.29075 62.64535,400.11903 C81.14117,390.94731 102.44835,382.37935 110.97747,363.57859 C123.23539,336.55859 102.26381,307.25559 86.24816,282.27886 C74.3667156,263.743847 64.7240148,243.865906 57.52184,223.061 C54.63534,214.71905 52.1215,206.0699 52.30035,197.24447 C52.55793,184.53487 58.33254,172.66875 64.06424,161.32203 C83.15824,123.52275 103.137147,86.1880833 124.00096,49.31803" id="Shape" stroke="#3F3D56"></path>
                    <path d="M148.07906,7.28097 L127.94368,13.0339 L129.34187,3.71243 C127.56917,3.59835 125.7701,3.5339 123.94368,3.5339 C97.71016,3.5339 76.44368,15.62222 76.44368,30.5339 C76.44368,45.44558 97.71016,57.5339 123.94368,57.5339 C150.1772,57.5339 171.44368,45.44558 171.44368,30.5339 C171.44368,20.63211 162.06084,11.9802 148.07906,7.28097 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M148.07906,4.28097 L127.94368,10.0339 L129.34187,0.71243 C127.56917,0.59835 125.7701,0.5339 123.94368,0.5339 C97.71016,0.5339 76.44368,12.62222 76.44368,27.5339 C76.44368,42.44558 97.71016,54.5339 123.94368,54.5339 C150.1772,54.5339 171.44368,42.44558 171.44368,27.5339 C171.44368,17.63211 162.06084,8.9802 148.07906,4.28097 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M89.07906,350.28097 L68.94368,356.0339 L70.34187,346.71243 C68.56917,346.59835 66.7701,346.5339 64.94368,346.5339 C38.71016,346.5339 17.44368,358.62222 17.44368,373.5339 C17.44368,388.44558 38.71016,400.5339 64.94368,400.5339 C91.1772,400.5339 112.44368,388.44558 112.44368,373.5339 C112.44368,363.63211 103.06084,354.9802 89.07906,350.28097 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M89.07906,347.28097 L68.94368,353.0339 L70.34187,343.71243 C68.56917,343.59835 66.7701,343.5339 64.94368,343.5339 C38.71016,343.5339 17.44368,355.62222 17.44368,370.5339 C17.44368,385.44558 38.71016,397.5339 64.94368,397.5339 C91.1772,397.5339 112.44368,385.44558 112.44368,370.5339 C112.44368,360.63211 103.06084,351.9802 89.07906,347.28097 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M159.07906,278.28097 L138.94368,284.0339 L140.34187,274.71243 C138.56917,274.59835 136.7701,274.5339 134.94368,274.5339 C108.71016,274.5339 87.44368,286.62222 87.44368,301.5339 C87.44368,316.44558 108.71016,328.5339 134.94368,328.5339 C161.1772,328.5339 182.44368,316.44558 182.44368,301.5339 C182.44368,291.63211 173.06084,282.9802 159.07906,278.28097 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M159.07906,275.28097 L138.94368,281.0339 L140.34187,271.71243 C138.56917,271.59835 136.7701,271.5339 134.94368,271.5339 C108.71016,271.5339 87.44368,283.62222 87.44368,298.5339 C87.44368,313.44558 108.71016,325.5339 134.94368,325.5339 C161.1772,325.5339 182.44368,313.44558 182.44368,298.5339 C182.44368,288.63211 173.06084,279.9802 159.07906,275.28097 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M76.30852,94.55137 L55.74863,90.57384 L61.21216,82.89305 C59.68279,81.98943 58.10746,81.11814 56.47859,80.29196 C33.08246,68.42524 8.64801,69.58622 1.90272,82.88507 C-4.84257,96.18392 8.6556,116.58465 32.05172,128.45136 C55.44784,140.31807 79.88231,139.1571 86.6276,125.85826 C91.10667,117.02743 86.65238,105.06699 76.30852,94.55137 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M77.66557,91.87585 L57.10568,87.89832 L62.56921,80.21752 C61.03984,79.31391 59.46451,78.44261 57.83564,77.61644 C34.43951,65.74972 10.00505,66.9107 3.25977,80.20954 C-3.48551,93.50838 10.01265,113.90912 33.40877,125.77584 C56.80489,137.64256 81.23935,136.48158 87.98464,123.18273 C92.46371,114.35191 88.00943,102.39147 77.66557,91.87585 Z" id="Shape" stroke="#3F3D56"></path>
                    <path d="M121.30852,199.55137 L100.74863,195.57384 L106.21216,187.89305 C104.68279,186.98943 103.10746,186.11814 101.47859,185.29196 C78.08246,173.42524 53.64801,174.58622 46.90272,187.88507 C40.15743,201.18392 53.6556,221.58465 77.05172,233.45136 C100.44784,245.31807 124.88231,244.1571 131.6276,230.85826 C136.10667,222.02743 131.65238,210.06699 121.30852,199.55137 Z" id="Shape" fill="#FF6584" fillRule="nonzero"></path>
                    <path d="M122.66557,196.87585 L102.10568,192.89832 L107.56921,185.21752 C106.03984,184.31391 104.46451,183.44261 102.83564,182.61644 C79.43951,170.74972 55.00505,171.9107 48.25977,185.20954 C41.51449,198.50838 55.01265,218.90912 78.40877,230.77584 C101.80489,242.64256 126.23935,241.48158 132.98464,228.18273 C137.46371,219.35191 133.00943,207.39147 122.66557,196.87585 Z" id="Shape" stroke="#3F3D56"></path>
                </g>
                <path d="M8.94368,778.0339 L1060.94368,778.0339" id="Shape" stroke="#3F3D56"></path>
                <path d="M535.12165,0.5 C429.63525,0.5 344.12165,57.31583 344.12165,127.4017 C344.12165,158.69559 361.18052,187.338 389.44365,209.46418 L389.44365,301.5678 L449.98975,241.02166 C477.479838,249.949333 506.21848,254.432952 535.12162,254.30339 C640.60801,254.30339 726.12162,197.48756 726.12162,127.4017 C726.12162,57.31584 640.60804,0.5 535.12165,0.5 Z" id="Shape" stroke="#3F3D56"></path>
                <path d="M452.13177,328.86443 C452.13177,328.86443 448.2897,277.12383 450.0986,267.66073 C451.9075,258.19763 453.6066,231.26048 443.66098,240.1153 C433.71536,248.97012 437.24864,269.0603 437.24864,269.0603 C437.24864,269.0603 427.81081,323.74186 436.53984,331.16047 C445.26887,338.57908 452.13177,328.86443 452.13177,328.86443 Z" id="Shape" fill="#A0616A" fillRule="nonzero"></path>
                <path d="M459.29473,380.03133 C459.29473,380.03133 429.69115,353.62813 431.29134,331.22542 C431.29134,331.22542 448.89347,317.62378 452.89395,324.82465 C456.89443,332.02552 485.97269,360.11765 481.03472,369.67443 C476.09675,379.23121 459.29473,380.03133 459.29473,380.03133 Z" id="Shape" fill="#6F3CD1" fillRule="nonzero"></path>
                <path d="M564.10741,332.82562 C564.10741,332.82562 581.70954,284.01971 582.50964,274.41855 C583.30974,264.81739 588.91042,238.41419 596.11129,249.61555 C603.31216,260.81691 594.51109,279.21914 594.51109,279.21914 C594.51109,279.21914 588.91042,334.42582 578.50916,339.2264 C568.1079,344.02698 564.10741,332.82562 564.10741,332.82562 Z" id="Shape" fill="#A0616A" fillRule="nonzero"></path>
                <path d="M516.1016,577.6552 L563.30732,577.6552 L565.70761,601.6581 L554.50625,604.05839 C554.50625,604.05839 528.10306,596.05739 512.90125,598.45771 C497.69944,600.85803 516.1016,577.6552 516.1016,577.6552 Z" id="Shape" fill="#A0616A" fillRule="nonzero"></path>
                <path d="M547.30538,574.45486 C547.30538,574.45486 569.70809,559.25302 573.70858,572.05457 C577.70907,584.85612 592.1108,631.26173 587.31022,636.06231 C582.50964,640.86289 576.90896,632.06183 576.90896,632.06183 C576.90896,632.06183 567.3078,612.05941 560.10696,611.25931 C552.90612,610.45921 548.9056,604.05844 548.9056,604.05844 C548.9056,604.05844 569.70809,588.0565 547.30538,574.45486 Z" id="Shape" fill="#2F2E41" fillRule="nonzero"></path>
                <path d="M504.10015,698.46987 C504.10015,698.46987 512.90122,734.47422 511.30102,740.07487 C509.70082,745.67552 536.90412,742.47516 536.90412,742.47516 L538.50432,732.0739 C538.50432,732.0739 522.50238,706.47081 522.50238,695.26945 C522.50238,684.06809 504.10015,698.46987 504.10015,698.46987 Z" id="Shape" fill="#A0616A" fillRule="nonzero"></path>
                <path d="M536.90412,724.87306 C536.90412,724.87306 558.50674,740.875 547.30538,748.87597 C536.10402,756.87694 494.49899,777.67945 494.49899,777.67945 C494.49899,777.67945 479.29715,780.07974 490.49851,766.4781 C501.69987,752.87646 504.90025,750.47616 505.70035,743.27529 C506.395657,738.480501 508.075457,733.88142 510.63413,729.76722 C510.63413,729.76722 521.70228,752.07636 536.90412,724.87306 Z" id="Shape" fill="#2F2E41" fillRule="nonzero"></path>
                <path d="M471.29618,532.84982 C471.29618,532.84982 462.49512,616.05989 476.09676,642.46309 C489.6984,668.86629 505.70035,704.87064 504.90025,706.47084 C504.10015,708.07104 528.10306,704.07055 528.10306,696.86967 C528.10306,689.66879 501.69986,617.66009 501.69986,617.66009 C501.69986,617.66009 512.90122,524.04876 521.70228,517.64798 C530.50334,511.2472 471.29618,532.84982 471.29618,532.84982 Z" id="Shape" fill="#2F2E41" fillRule="nonzero"></path>
                <path d="M471.29618,532.84982 C471.29618,532.84982 462.49512,616.05989 476.09676,642.46309 C489.6984,668.86629 505.70035,704.87064 504.90025,706.47084 C504.10015,708.07104 528.10306,704.07055 528.10306,696.86967 C528.10306,689.66879 501.69986,617.66009 501.69986,617.66009 C501.69986,617.66009 512.90122,524.04876 521.70228,517.64798 C530.50334,511.2472 471.29618,532.84982 471.29618,532.84982 Z" id="Shape" fill="#000000" fillRule="nonzero" opacity="0.1"></path>
                <path d="M489.37984,298.0214 C483.66075,299.05687 477.99472,302.19882 475.15059,307.56494 C472.49759,312.5705 472.32885,319.2613 468.1081,322.85583 C465.58849,325.00162 462.16768,325.49783 459.35451,327.18347 C455.285745,329.786179 452.920206,334.366655 453.15333,339.19103 C453.37859,343.60536 455.70674,347.71716 455.65733,352.13765 C455.5931,357.88465 451.61919,362.62965 447.88159,366.78912 L460.27703,369.08934 C462.150014,369.589803 464.114567,369.641894 466.01144,369.24139 C468.15244,368.60758 469.89144,366.84469 472.05571,366.30716 C475.15924,365.53636 478.23022,367.42416 480.99937,369.09916 C488.88182,373.8669 497.33374,377.4654 505.75507,381.04835 C508.23257,382.10244 511.04157,383.15762 513.48628,382.02057 C515.46067,381.10226 516.6886,378.95568 518.54572,377.7941 C521.87136,375.71397 526.04731,377.27972 529.78441,378.30662 C538.235763,380.600189 547.225657,379.834669 555.16753,376.14516 C553.45546,374.14531 551.21153,372.74669 549.36658,370.88468 C547.52163,369.02267 546.02902,366.38594 546.51643,363.73403 C547.01349,361.02964 549.3386,359.22431 550.92337,357.0417 C553.287865,353.548775 554.149875,349.252328 553.31573,345.11765 C552.484021,341.045969 550.503424,337.297723 547.60827,334.31638 C545.955741,332.769452 544.396742,331.125546 542.93954,329.39338 C540.02344,325.45545 539.30154,320.24207 538.36868,315.33256 C537.43582,310.42305 535.96294,305.19814 532.17631,302.20871 C526.14371,297.44613 516.34731,299.06952 509.2949,298.5968 C502.57105,298.14606 496.08817,298.0214 489.37984,298.0214 Z" id="Shape" fill="#2F2E41" fillRule="nonzero"></path>
                <path d="M449.69357,476.04295 L464.89541,513.6475 C464.89541,513.6475 395.28698,589.6567 434.49173,600.05796 C473.69648,610.45922 487.29812,592.05696 512.10112,606.45873 C512.10112,606.45873 524.10257,588.0565 522.50238,576.85515 C522.50238,576.85515 488.09822,570.45437 469.69599,571.25447 C469.69599,571.25447 519.30199,528.04924 524.10257,526.44905 C528.90315,524.84886 544.90509,499.24576 520.10209,478.44324 C495.29909,457.64072 501.69984,446.43937 501.69984,446.43937 L449.69357,476.04295 Z" id="Shape" fill="#2F2E41" fillRule="nonzero"></path>
                <path d="M485.69792,345.6272 C485.69792,345.6272 488.09822,370.4302 477.69692,373.63059 C467.29562,376.83098 518.50185,376.03088 518.50185,376.03088 C518.50185,376.03088 506.5004,368.83001 512.10108,353.62817 C517.70176,338.42633 485.69792,345.6272 485.69792,345.6272 Z" id="Shape" fill="#A0616A" fillRule="nonzero"></path>
                <path d="M485.69792,345.6272 C485.69792,345.6272 488.09822,370.4302 477.69692,373.63059 C467.29562,376.83098 518.50185,376.03088 518.50185,376.03088 C518.50185,376.03088 506.5004,368.83001 512.10108,353.62817 C517.70176,338.42633 485.69792,345.6272 485.69792,345.6272 Z" id="Shape" fill="#000000" fillRule="nonzero" opacity="0.1"></path>
                <path d="M540.10451,388.83239 C540.10451,388.83239 535.30393,364.02939 525.70277,365.62959 C516.10161,367.22979 496.89928,368.82997 494.49899,370.43017 C492.0987,372.03037 479.29715,375.30959 479.29715,375.30959 C479.29715,375.30959 484.09773,369.63007 479.29715,368.82997 C474.49657,368.02987 459.29473,372.03036 457.69454,375.23075 C456.09435,378.43114 449.69354,384.83191 449.69354,396.03326 C449.69354,407.23461 444.89296,426.43694 446.49315,434.43791 C448.09334,442.43888 445.69305,460.84111 440.89247,471.24237 C436.09189,481.64363 440.09238,492.04488 448.09334,481.64363 C456.0943,471.24238 452.09383,481.64363 458.4946,482.44372 C464.89537,483.24381 493.69889,453.6402 501.69984,460.84111 C509.70079,468.04202 517.70178,472.84256 519.30197,468.84211 C520.90216,464.84166 512.9012,457.64075 512.9012,454.44036 C512.9012,451.23997 500.09965,464.84162 513.70129,436.83823 C527.30293,408.83484 514.50141,400.83385 540.10451,388.83239 Z" id="Shape" fill="#6F3CD1" fillRule="nonzero"></path>
                <path d="M533.70373,387.2322 L540.10451,388.83239 C540.10451,388.83239 563.30732,372.03039 569.70809,360.02891 C576.10886,348.02743 580.90945,338.42629 580.90945,338.42629 C580.90945,338.42629 570.50819,325.62474 564.10745,330.42529 C557.70671,335.22584 528.90319,368.02985 528.90319,368.02985 L533.70373,387.2322 Z" id="Shape" fill="#6F3CD1" fillRule="nonzero"></path>
                <circle id="Oval" fill="#A0616A" fillRule="nonzero" cx="504.10015" cy="337.6262" r="24.803"></circle>
                <path d="M482.15861,330.44111 C486.397034,326.925245 491.809774,325.145107 497.30769,325.45889 C506.81523,326.04395 515.18454,332.81883 524.70769,333.03052 C524.326403,330.971466 524.613346,328.844443 525.52662,326.96003 C526.26512,325.44662 527.43062,323.98018 527.27384,322.30353 C527.012663,320.972859 526.283994,319.779551 525.21958,318.93936 C519.7149,313.71969 512.104,311.02952 504.52531,310.69813 C499.44418,310.47594 489.74614,310.59634 485.80537,314.44379 C482.43384,317.7355 482.82584,326.1742 482.15861,330.44111 Z" id="Shape" fill="#2F2E41" fillRule="nonzero"></path>
                <g id="Group-3" transform="translate(473.000000, 119.000000)" fillRule="nonzero" fill="#6F3CD1">
                    <ellipse id="Oval" cx="15.4655172" cy="15.5" rx="14.7931034" ry="14.826087"></ellipse>
                    <ellipse id="Oval" cx="58.5" cy="15.5" rx="14.7931034" ry="14.826087"></ellipse>
                    <ellipse id="Oval" cx="101.534483" cy="15.5" rx="14.7931034" ry="14.826087"></ellipse>
                </g>
            </g>
        </g>
    </svg>
  }

  render() {
    return (
      <header
        style={{
          marginBottom: `1.45rem`,
        }}
      >
        { this.renderGoBack() }
        { this.renderMenu() }
        { this.renderModal() }

        <div
          style={{
            margin: `0 auto`,
            maxWidth: 200,
            padding: `1.45rem 0`,
            textAlign: `center`,
          }}
        >
          <Link
            to="/"
            title="Hiome Dashboard"
            style={{
              color: `white`,
              textDecoration: `none`,
              padding: `20px`
            }}
          >
            <svg width="24px" height="40px" version="1.1" viewBox="0 0 34 57" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <g fill="#cbb9ec">
                <path id="marka" d="m23.656 38v19h-2.1738v-9.4473h-8.5449v9.4473h-2.1738v-19h-9.9547v-2.544h8.162v-0.159c-0.98934-0.848-1.9257-1.8462-2.809-2.9945s-1.6695-2.4292-2.3585-3.8425c-0.689-1.4133-1.2367-2.9503-1.643-4.611-0.40634-1.6607-0.6095-3.445-0.6095-5.353 0-2.6853 0.40633-5.1233 1.219-7.314 0.81267-2.1907 1.9345-4.0633 3.3655-5.618 1.431-1.5547 3.1093-2.756 5.035-3.604s4.0015-1.272 6.2275-1.272c2.332 0 4.4432 0.46816 6.3335 1.4045s3.5157 2.2083 4.876 3.816c1.3603 1.6077 2.4115 3.4803 3.1535 5.618 0.742 2.1377 1.113 4.3902 1.113 6.7575 0 1.9787-0.22083 3.8425-0.6625 5.5915s-1.0158 3.3478-1.7225 4.7965-1.5105 2.7295-2.4115 3.8425-1.8108 2.0405-2.7295 2.7825v0.159h8.268v2.544h-9.9598zm-2.1738 0h-0.21542v-1.855c1.06-0.70667 2.1023-1.6077 3.127-2.703s1.9345-2.3762 2.7295-3.8425 1.4398-3.1093 1.9345-4.929 0.742-3.7895 0.742-5.9095c0-1.7313-0.265-3.5157-0.795-5.353-0.53-1.8373-1.3162-3.5068-2.3585-5.0085-1.0423-1.5017-2.3408-2.7295-3.8955-3.6835s-3.3567-1.431-5.406-1.431c-1.9433 0-3.7012 0.40633-5.2735 1.219s-2.9062 1.9257-4.0015 3.339-1.9433 3.0652-2.544 4.9555-0.901 3.9132-0.901 6.0685c0 1.9787 0.24733 3.869 0.742 5.671s1.1395 3.445 1.9345 4.929c0.795 1.484 1.7048 2.8002 2.7295 3.9485 1.0247 1.1483 2.067 2.0582 3.127 2.7295v1.855h-0.2205v7.1875h8.5449v-7.1875z"/>
              </g>
            </svg>
          </Link>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  goBack: PropTypes.bool,
  menuOptions: PropTypes.arrayOf(PropTypes.node),
  menuCallback: PropTypes.func
}

Header.defaultProps = {
  goBack: false,
  menuOptions: [],
  menuCallback: undefined
}

export default Header
